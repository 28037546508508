<template>
  <div class="container-app">
    <div class="container-quiz" v-if="!submitted">
      <div class="header-quiz">
        <h1>Basic Info</h1>
      </div>
      <div class="form-group mt-5">
        <label>Revision Name</label>
        <input
          type="input"
          v-model="title"
          class="form-control mb-4"
          name="title"
        />
        <label>Category</label>

        <select
          name="revision-categories"
          class="form-control"
          id="revision-categories"
          v-model="category"
        >
          <option v-for="(category, index) in categories" :key="index">
            {{ category }}
          </option>
        </select>
        <button class="btn btn-warning my-5 mx-2" @click="div_show">
          Create Category
        </button>
        <button class="btn btn-success my-5 mx-2" @click="validate1">
          Next
        </button>
      </div>
    </div>
    <div class="container-quiz" v-else>
      <div class="header-quiz">
        <h1>Revision Created</h1>
      </div>
      <button class="btn btn-success my-5" @click="redirect">
        Edit Revision
      </button>
    </div>
    <div id="abc">
      <!-- Popup Div Starts Here -->
      <div id="popupContact">
        <!-- Contact Us Form -->
        <div id="fo">
          <div @click="div_hide" class="cross-icon">
            <span
              class="iconify"
              data-icon="ant-design:close-circle-filled"
              id="close"
            ></span>
          </div>

          <h2>Contact Us</h2>
          <hr />
          <input
            id="name"
            name="name"
            placeholder="Name"
            type="text"
            v-model="new_category"
          />
          <input
            id="imageUrl"
            name="imageUrl"
            v-model="image_url"
            placeholder="Image Url"
            type="text"
          />
          <button class="btn my-3" @click="create_category">
            Create Category
          </button>
        </div>
      </div>
      <!-- Popup Div Ends Here -->
    </div>
  </div>
</template>
<script>
import store from "@/store";
import db from "../../firebase.js";
export default {
  async mounted() {
    const docs = await db.collection("revision_categories").get();
    this.categories = docs.docs.map((doc) => doc.data().name);
    store.state.loading = false;
  },
  data() {
    return {
      submitted: false,
      categories: ["Geography", "Physics", "Maths"],
      title: "",
      category: "",
      start_time: "",
      duration: "",
      test_id: "",
      new_category: "",
      image_url: "",
    };
  },
  computed: {
    getStartDate() {
      if (this.start_time !== "") {
        return new Date(this.start_time);
      }
    },
  },
  methods: {
    redirect() {
      this.$router.push({
        name: "EditRevision",
        params: { revision_id: this.test_id },
      });
    },
    create_category() {
      db.collection("revision_categories")
        .add({
          name: this.new_category,
          img: this.image_url,
        })
        .then((docid) => {
          // console.log(docid);
          swal("Category " + this.new_category + " created");
        })
        .catch((err) => swal(err));
    },
    div_show() {
      document.getElementById("abc").style.display = "block";
    },
    //Function to Hide Popup
    div_hide() {
      document.getElementById("abc").style.display = "none";
    },
    validate1() {
      if (this.title != "") {
        db.collection("revisions")
          .add({
            title: this.title,
            category: this.category,
            total: 0,
          })
          .then((docid) => {
            this.submitted = true;
            this.test_id = docid.id;
          })
          .catch((error) => swal(error));
      } else swal(" Title!=''");
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  letter-spacing: 2px;
}
.container-app {
  min-height: 100%;
  width: 100%;
  position: absolute;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(239, 239, 238, 1) 100%
  );
}
.editable-ques {
  width: 60%;
  margin-top: 20px;
}
.header-quiz {
  display: flex;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #e7eae0;
  justify-content: center;
  align-items: center;
  background-color: #e7eae0;
  border-radius: 10px 10px 0px 0px;
}

.container-app {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.container-quiz {
  display: flex;
  min-width: 40%;
  height: 85%;
  background-color: white;
  text-align: center;
  flex-flow: column;
  border: 1px solid #e7eae0;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.questions {
  width: 100%;
}
#abc {
  width: 100%;
  height: 100%;
  opacity: 0.95;
  top: 0;
  left: 0;
  display: none;
  position: fixed;
  background-color: #313131;
  overflow: auto;
}
img#close {
  position: absolute;
  right: -14px;
  top: -14px;
  cursor: pointer;
}
div#popupContact {
  position: absolute;
  left: 50%;
  top: 17%;
  margin-left: -202px;
  font-family: "Raleway", sans-serif;
}
#fo {
  max-width: 300px;
  min-width: 250px;
  padding: 10px 50px;
  border: 2px solid gray;
  border-radius: 10px;
  font-family: raleway;
  background-color: #fff;
}
input[type="text"] {
  width: 82%;
  padding: 10px;
  margin-top: 30px;
  border: 1px solid #ccc;
  padding-left: 40px;
  font-size: 16px;
  font-family: raleway;
}
#name {
  background-image: url("https://media.istockphoto.com/vectors/user-avatar-profile-icon-black-vector-illustration-vector-id1209654046?k=20&m=1209654046&s=612x612&w=0&h=Atw7VdjWG8KgyST8AXXJdmBkzn0lvgqyWod9vTb2XoE=");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
#imageUrl {
  background-image: url("https://cdn2.iconfinder.com/data/icons/pittogrammi/142/95-512.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.cross-icon {
  display: flow-root;
  text-align: right;
}
</style>
